import React from 'react';
import styled from 'styled-components';
import { H3, P } from './Typography';
import TickedItems from './TickedItems';

const Wrapper = styled.div<{ customGap?: string }>`
  display: flex;
  flex-direction: column;
  gap: ${({ customGap }) => customGap || `80px`};
`;

const ReversibleCardWrapper = styled.div<{ reverse?: boolean }>`
  display: grid;
  grid-template-columns: ${({ reverse }) =>
    reverse ? `1.1fr 0.9fr` : `0.9fr 1.1fr`};
  gap: 130px;

  @media (max-width: 850px) {
    display: flex;
    flex-direction: column;
  }
`;

const Column = styled.div<{ image?: string }>`
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${({ image }) =>
    image &&
    `background-image: url(${image}); 
    background-repeat: no-repeat; 
    background-size: contain;
    background-position: center center; 
    height: 400px; 
    border-radius: 10px;
    
    @media (max-width: 850px) {
      display: none;
    }`}
`;

const RedText = styled(P)`
  color: #ef6355;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 10px;
`;

const GreyText = styled(RedText)`
  font-weight: 600;
  color: #6c6c8a;
`;

const CardTitle = styled(H3)`
  font-size: 1.5rem;
  margin-bottom: 16px;

  @media (max-width: 850px) {
    text-align: center;
  }
`;

const Description = styled(P)`
  color: #495b6c;
  font-size: 1rem;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 16px;
`;

const FlexImageWrapper = styled.div`
  display: none;
  @media (max-width: 850px) {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.75rem 0.75rem 1.5rem;
  }
`;

const FlexImage = styled.img`
  width: 100%;
  max-height: 300px;
  object-fit: contain;
`;

const Subtitle = styled(P)`
  font-size: 1rem;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 16px;
`;

interface ComponentProps {
  className?: string;
  reversedStart?: boolean;
  customGap?: string;
  cards: {
    title: string;
    subtitle?: string;
    redText?: string;
    greyText?: string;
    tickedDescription?: { item: string }[];
    description1?: string;
    description2?: string;
    image: string;
  }[];
}

const ReversibleCardsComponent: React.FunctionComponent<ComponentProps> = ({
  cards,
  reversedStart,
  customGap,
  className,
}) => (
  <Wrapper customGap={customGap}>
    {cards.map((card, i) => (
      <ReversibleCardWrapper
        className={className}
        key={card.title}
        reverse={(i + 1 + (reversedStart ? 1 : 0)) % 2 === 0}
      >
        {(i + 1 + (reversedStart ? 1 : 0)) % 2 === 0 ? (
          <>
            <Column image={card.image} />
            <Column>
              {card.redText && <RedText>{card.redText}</RedText>}
              {card.greyText && <GreyText>{card.greyText}</GreyText>}
              <CardTitle>{card.title}</CardTitle>
              <FlexImageWrapper>
                <FlexImage src={card.image} />
              </FlexImageWrapper>
              {card.subtitle && <Subtitle>{card.subtitle}</Subtitle>}
              {card.tickedDescription && (
                <TickedItems items={card.tickedDescription} />
              )}
              {card.description1 && (
                <Description>{card.description1}</Description>
              )}
              {card.description2 && (
                <Description>{card.description2}</Description>
              )}
            </Column>
          </>
        ) : (
          <>
            <Column>
              {card.redText && <RedText>{card.redText}</RedText>}
              {card.greyText && <GreyText>{card.greyText}</GreyText>}
              <CardTitle>{card.title}</CardTitle>
              <FlexImageWrapper>
                <FlexImage src={card.image} />
              </FlexImageWrapper>
              {card.subtitle && <Subtitle>{card.subtitle}</Subtitle>}
              {card.tickedDescription && (
                <TickedItems items={card.tickedDescription} />
              )}
              {card.description1 && (
                <Description>{card.description1}</Description>
              )}
              {card.description2 && (
                <Description>{card.description2}</Description>
              )}
            </Column>
            <Column image={card.image} />
          </>
        )}
      </ReversibleCardWrapper>
    ))}
  </Wrapper>
);

export default ReversibleCardsComponent;
